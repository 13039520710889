import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ProductsService } from 'src/app/api/products/products.service';
import { CertificationsService } from 'src/app/api/certitications/certifications.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  form: FormGroup;
  userData: any;
  loading: boolean = true;
  categoriesData: any[];
  uploadedImageUrls: any = []
  uploadedDocumentUrl: string = '';
  uploadedVideoUrl: string = '';
  certificationsData: any = [];
  certificationsInput = new FormControl();
  loadingCertifications: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private productsProvider: ProductsService,
    private certificationsProvider: CertificationsService,
    public snackBar: MatSnackBar,
  ) {
    this.certificationsInput = new FormControl( this.data ? this.data.certifications : [], Validators.nullValidator );

    this.form = this.formBuilder.group({
      productName: [this.data ? this.data.productName : null, Validators.required],
      sku: [this.data ? this.data.sku : null, Validators.required],
      sortingNumber: [this.data ? this.data.sortingNumber : null, Validators.required],
      category: [this.data ? this.data.category : null, Validators.required],
      quantityInPrimaryPack: [this.data ? this.data.quantityInPrimaryPack : null, Validators.nullValidator],
      quantityInBulkPack: [this.data ? this.data.quantityInBulkPack : null, Validators.nullValidator],
      unitPrice: [this.data ? this.data.unitPrice : null, Validators.nullValidator],
      productDesc: [this.data ? this.data.productDesc : null, Validators.nullValidator],
      cartonSize: [this.data ? this.data.cartonSize : null, Validators.nullValidator],
      cartonWeight: [this.data ? this.data.cartonWeight : null, Validators.nullValidator],
      productWeight: [this.data ? this.data.productWeight : null, Validators.nullValidator],
      productionVolume: [this.data ? this.data.productWeight : null, Validators.nullValidator],
      hasFDACertificate: [this.data ? this.data.hasFDACertificate : null, Validators.nullValidator],
      hasCECertificate: [this.data ? this.data.hasCECertificate : null, Validators.nullValidator],
      minimumOrderQty: [this.data ? this.data.minimumOrderQty : null, Validators.nullValidator],
    });
    this.uploadedImageUrls = this.data ? this.data.imageUrls : [];
    this.uploadedDocumentUrl = this.data ? this.data.documentUrl : '';
    this.uploadedVideoUrl = this.data ? this.data.videoUrl : '';

    this.loadingCertifications = true;
    this.certificationsProvider.getCertifications().valueChanges().subscribe((data) => {
      this.loadingCertifications = false;
      this.certificationsData = data;
    })
  }

  ngOnInit() {
    this.loadCategories();
  }

  getCertificationName(id) {
    if(id && this.certificationsData) {
      let certificationName = this.certificationsData.find(certification => certification.id === id);
      return certificationName.certificationShortName;
    }
  }

  loadCategories() {
    this.loading = true;
    this.productsProvider.getCategories().valueChanges().subscribe((data)=> {
      this.categoriesData = data;
      this.loading = false;
    })
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onImageUploaded(url) {
    this.uploadedImageUrls.push(url);
    console.log(this.uploadedImageUrls);
  }

  removeImage(index) {
    this.uploadedImageUrls.splice(index, 1);
  }

  onDocumentUploaded(url) {
    this.uploadedDocumentUrl = url;
  }

  removeDocument() {
    this.uploadedDocumentUrl = '';
  }

  onVideoUploaded(url) {
    this.uploadedVideoUrl = url;
  }

  
  save() {
    this.userData = this.form.value;
    console.log(this.form.value);
    this.userData.certifications = this.certificationsInput.value;
    if(this.data) {
      this.userData.id = this.data.id;
      this.userData.imageUrls = this.uploadedImageUrls ? this.uploadedImageUrls : this.data.imageUrls;
      this.userData.documentUrl = this.uploadedDocumentUrl;
      this.userData.videoUrl = this.uploadedVideoUrl ? this.uploadedVideoUrl : '';
      this.productsProvider.updateProduct(this.userData).then((data)=> {
        this.onCancel();
      })
    }
    else {
      this.userData.imageUrls = this.uploadedImageUrls;
      this.userData.documentUrl = this.uploadedDocumentUrl;
      this.userData.videoUrl = this.uploadedVideoUrl ? this.uploadedVideoUrl : '';
      this.productsProvider.addProduct(this.userData).then((data)=> {
        this.onCancel();
      })
    }
  }
}
