import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
declare var UIkit: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mediex';
  itemOncart: any = null;

  constructor(
    private viewportScroller: ViewportScroller
  ) {

  }

  ngOnInit() {
    setInterval(() => { 
      let productsOnCart = localStorage.getItem('mediex_products');
      this.itemOncart = JSON.parse(productsOnCart);
    }, 1000);
  }

  onMenuClick() {
    UIkit.offcanvas('#sidemenu').hide();
  }

  getCartString() {
    if(!this.itemOncart) {
      return 'Cart empty';
    }
    else {
      if(this.itemOncart.length === 1) {
        return `(${this.itemOncart.length}) item`;
      }
      else if(this.itemOncart.length === 0) {
        return 'Cart empty';
      }
      else if(this.itemOncart.length > 1) {
        return `(${this.itemOncart.length}) items`;
      }
    }
  }

  opeRequestQuoteModal() {
    UIkit.modal('#modal-quote-request').show();
  }

  public goToCetifications(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 10);
  }
}
