import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/api/products/products.service';
import { CertificationsService } from 'src/app/api/certitications/certifications.service';
import { Title } from '@angular/platform-browser';
declare var UIkit: any;

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  selectedProduct: any = '';
  loading: boolean = true;
  productData: any;
  descPlaceholder: any = 'Aliquam aliquet, est a ullamcorper condimentum, tellus nulla fringilla elit, a iaculis nulla turpis sed wisi. Fusce volutpat. Etiam sodales ante id nunc. Proin ornare dignissim lacus. Nunc porttitor nunc a sem. Sed sollicitudin velit eu magna. Aliquam erat volutpat. Vivamus ornare est non wisi. Proin vel quam. Vivamus egestas. Nunc tempor diam vehicula mauris. Nullam sapien eros, facilisis vel, eleifend non, auctor dapibus, pede..';
  categoryDetails: any;
  selectedImage: any = '';
  itemOncart: any;
  qty: number = 0;
  certificationsData: any[];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public productsProvider: ProductsService,
    private certificationsProvider: CertificationsService,
    private titleService: Title,
  ) {
    this.route.params.subscribe(params => {
      this.selectedProduct = params['productId'];
      this.productsProvider.getProductById(this.selectedProduct).valueChanges().subscribe((data)=> {
        this.productData = data[0];
        this.titleService.setTitle(this.productData.productName);
        if(this.productData.minimumOrderQty) {
          let cleanNumber = this.productData.minimumOrderQty.replace(/ /g,'')
          this.qty = parseInt(cleanNumber);
        }
        else {
          this.qty = 100;
        }
        this.selectedImage = this.productData.imageUrls[0];
        this.loadCategory(this.productData.category);
      });

    });

    this.certificationsProvider.getCertifications().valueChanges().subscribe((data) => {
      this.certificationsData = data;
    })
  }

  ngOnInit() {
  let productsOnCart = localStorage.getItem('mediex_products');
  this.itemOncart = JSON.parse(productsOnCart);
  
  }

  getCertificationDetails(id) {
    if(id && this.certificationsData) {
      let certificationDetails = this.certificationsData.find(certification => certification.id === id);
      return certificationDetails;
    }
  }

  onSliderChange(item) {
    this.selectedImage = item;
  }
  
  addToRequests(product) {
    let productData = product;
    productData.qty = this.qty;
    this.productsProvider.addProductToCart(productData);
  }

  loadCategory(id) {
    this.productsProvider.getCategoryById(id).valueChanges().subscribe((data)=> {
      this.categoryDetails = data[0];
    })
  }

  opeRequestQuoteModal() {
    UIkit.modal('#modal-quote-request').show();
  }

  downloadProdcutSpecDoc() {
    console.log('downloading');
  }



}
