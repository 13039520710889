import { Component, OnInit } from '@angular/core';
declare var UIkit: any;
@Component({
  selector: 'app-page-slider',
  templateUrl: './page-slider.component.html',
  styleUrls: ['./page-slider.component.scss']
})
export class PageSliderComponent implements OnInit {

  constructor() {
    UIkit.slideshow('page-slider', {ratio: 7.2,animation: 'push', autoplay: true});
  }

  ngOnInit() {
  }

  

}
