import { Component, OnInit } from '@angular/core';
import { CertificationsService } from 'src/app/api/certitications/certifications.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent implements OnInit {
  certificatesData: any[];
  loading: boolean;

  constructor(
    public certificationsProvider: CertificationsService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadCertifications();
    this.setTitle('Certifications');
  }

  public setTitle( pageTitle: string) {
    this.titleService.setTitle( pageTitle );
  }

  loadCertifications() {
    this.loading = true;
    this.certificationsProvider.getCertifications().valueChanges().subscribe((data) => {
      this.certificatesData = data;
      this.loading = false;
    })
  }

}
