import { Component, OnInit } from '@angular/core';
import { ArticlesService } from 'src/app/api/articles/articles.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-public-articles',
  templateUrl: './public-articles.component.html',
  styleUrls: ['./public-articles.component.scss']
})
export class PublicArticlesComponent implements OnInit {
  loading: boolean;
  articlesData: any[];

  constructor(
    private articlesProvider: ArticlesService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadArticles();
    this.titleService.setTitle('News');
  }

  loadArticles() {
    this.loading = true;
    this.articlesProvider.getArticles().valueChanges().subscribe((data)=> {
      this.articlesData = data;
      this.loading = false;
    })
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

}
