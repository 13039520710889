import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { AdminDashboardComponent } from '../admin/pages/admin-dashboard/admin-dashboard.component';
import { AdminProductsComponent } from '../admin/pages/admin-products/admin-products.component';
import { AdminCategoriesComponent } from '../admin/pages/admin-categories/admin-categories.component';
import { ProductsComponent } from '../pages/products/products.component';
import { ProductDetailsComponent } from '../pages/product-details/product-details.component';
import { ManufacturingComponent } from '../pages/manufacturing/manufacturing.component';
import { QuotationRequestsComponent } from '../admin/pages/quotation-requests/quotation-requests.component';
import { QuotationRequestComponent } from '../admin/pages/quotation-request/quotation-request.component';
import { AboutComponent } from '../pages/about/about.component';
import { ContactsComponent } from '../pages/contacts/contacts.component';
import { SubscribersComponent } from '../admin/pages/subscribers/subscribers.component';
import { CertificationsComponent } from '../pages/certifications/certifications.component';
import { ArticlesComponent } from '../admin/pages/articles/articles.component';
import { PublicArticlesComponent } from '../pages/public-articles/public-articles.component';
import { ProductsCertificationsComponent } from '../admin/pages/products-certifications/products-certifications.component';


const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {
    path: 'home',
    component: HomeComponent,
    data: {
      pageTitle: 'Medi-ex Home page',  
      breadcrumb: 'Home',
    }
  },
  {
    path: 'public-products/:category',
    component: ProductsComponent,
    data: {
      pageTitle: 'Products',
      breadcrumb: 'Products',
    }
  },
  {
    path: 'public-product/:productId',
    component: ProductDetailsComponent,
    data: {
      pageTitle: 'Product Details',
      breadcrumb: 'Product',
    }
  },
  {
    path: 'public-articles',
    component: PublicArticlesComponent,
    data: {
      pageTitle: 'News',
      breadcrumb: 'News',
    }
  },
  {
    path: 'certifications',
    component: CertificationsComponent,
    data: {
      pageTitle: 'Certifications',
      breadcrumb: 'Certifications',
    }
  },
  {
    path: 'manufacturing',
    component: ManufacturingComponent,
    data: {
      pageTitle: 'Manufacturing',
      breadcrumb: 'Manufacturing',
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      pageTitle: 'About Medi-ex',
      breadcrumb: 'About',
    }
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: {
      pageTitle: 'Contact us',
      breadcrumb: 'Contacts',
    }
  },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    children: [
      {path: '', redirectTo: 'products',pathMatch: 'full'},
      {path: 'products', component: AdminProductsComponent},
      {path: 'products-certifications', component: ProductsCertificationsComponent},
      {path: 'categories', component: AdminCategoriesComponent},
      {path: 'quotation-requests', component: QuotationRequestsComponent},
      {path: 'quotation-requests/quotation-request/:id', component: QuotationRequestComponent},
      {path: 'subscribers', component: SubscribersComponent},
      {path: 'articles', component: ArticlesComponent},
    ]
  },
  {path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
