import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxLinkPreviewModule } from 'ngx-link-preview';
import { PopoverModule } from 'ngx-smart-popover';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './modules/app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { RequestQuoteComponent } from './pages/request-quote/request-quote.component';
import { FooterComponent } from './components/footer/footer.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AdminDashboardComponent } from './admin/pages/admin-dashboard/admin-dashboard.component';
import { AdminCategoriesComponent } from './admin/pages/admin-categories/admin-categories.component';
import { AdminProductsComponent } from './admin/pages/admin-products/admin-products.component';
import { LoginComponent } from './admin/modals/login/login.component';
import { AuthProvidersService } from './api/auth/auth-providers.service';
import { MaterialModule } from './modules/material.modules';
import { AddCategoriesComponent } from './admin/pages/admin-categories/add-categories/add-categories.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductsService } from './api/products/products.service';
import { FileUploaderComponent } from './components/reusable/file-uploader/file-uploader.component';
import { ConfirmDeleteDialogComponent } from './components/reusable/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDialogService } from './providers/modals/confirm-dialog.service';
import { AddProductComponent } from './admin/pages/admin-products/add-product/add-product.component';
import { ProductsComponent } from './pages/products/products.component';
import { PageSliderComponent } from './components/page-slider/page-slider.component';
import { ManufacturingComponent } from './pages/manufacturing/manufacturing.component';
import { RequestQuoteModalComponent } from './components/request-quote-modal/request-quote-modal.component';
import { QuotationRequestsComponent } from './admin/pages/quotation-requests/quotation-requests.component';
import { QuotationRequestComponent } from './admin/pages/quotation-request/quotation-request.component';
import { AboutComponent } from './pages/about/about.component';
import { ProductCardComponent } from './components/reusable/product-card/product-card.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { AgmCoreModule } from '@agm/core';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { SubscribersComponent } from './admin/pages/subscribers/subscribers.component';
import { CertificationsComponent } from './pages/certifications/certifications.component';
import { ArticlesComponent } from './admin/pages/articles/articles.component';
import { AddArticleComponent } from './admin/pages/articles/add-article/add-article.component';
import { PublicArticlesComponent } from './pages/public-articles/public-articles.component';
import { ProductsCertificationsComponent } from './admin/pages/products-certifications/products-certifications.component';
import { CertificationsService } from './api/certitications/certifications.service';
import { ArticlesService } from './api/articles/articles.service';
import { AddCertificateComponent } from './admin/pages/products-certifications/add-certificate/add-certificate.component';
import { PageBannerComponent } from './components/page-banner/page-banner.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductDetailsComponent,
    RequestQuoteComponent,
    FooterComponent,
    AdminDashboardComponent,
    AdminCategoriesComponent,
    AdminProductsComponent,
    LoginComponent,
    AddCategoriesComponent,
    FileUploaderComponent,
    ConfirmDeleteDialogComponent,
    AddProductComponent,
    ProductsComponent,
    PageSliderComponent,
    ManufacturingComponent,
    RequestQuoteModalComponent,
    QuotationRequestsComponent,
    QuotationRequestComponent,
    AboutComponent,
    ProductCardComponent,
    ContactsComponent,
    SubscribersComponent,
    CertificationsComponent,
    ArticlesComponent,
    AddArticleComponent,
    PublicArticlesComponent,
    ProductsCertificationsComponent,
    AddCertificateComponent,
    PageBannerComponent,
    BreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    MaterialModule,
    BrowserAnimationsModule,
    NgxLinkPreviewModule,
    PopoverModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA0n4Iez9acKdJAGkhHQjyzitf6FnW4MSQ'
    }),
    RecaptchaModule.forRoot({
      siteKey: '6LdBPuUUAAAAAKSXb4Z55uXk-t8yUxSTjFv_O9nB'
    }),
  ],
  providers: [
    AngularFirestore,
    AngularFireDatabase,
    AuthProvidersService,
    ProductsService,
    ConfirmDialogService,
    CertificationsService,
    ArticlesService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddCategoriesComponent,
    ConfirmDeleteDialogComponent,
    AddProductComponent,
    AddArticleComponent,
    AddCertificateComponent
  ],
})
export class AppModule { }
