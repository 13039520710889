import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { AddCategoriesComponent } from './add-categories/add-categories.component';
import { ProductsService } from 'src/app/api/products/products.service';
import { ConfirmDeleteDialogComponent } from 'src/app/components/reusable/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
declare var UIkit: any;

@Component({
  selector: 'app-admin-categories',
  templateUrl: './admin-categories.component.html',
  styleUrls: ['./admin-categories.component.scss']
})
export class AdminCategoriesComponent implements OnInit {
  categoriesData: any;
  loading: boolean = true;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public productsProvider: ProductsService,
    private confirmDialog: ConfirmDialogService,
  ) {
    this.loading = true;
    this.productsProvider.getCategories().valueChanges().subscribe((data)=> {
      this.categoriesData = data;
      console.log(data);
      this.loading = false;
    })
  }

  ngOnInit() {

  }

  deleteItem(data) {
    this.productsProvider.deleteCategory(data);
  }

  openDeleteCateGory(data) {
    this.confirmDialog.openConfirmDialog('delete', 'category', data).subscribe(res => {
      if(res) {
        this.productsProvider.deleteCategory(data).then((data) => {
          // this.snackBar.open(data['message'], 'CLOSE', { duration: 5000 });
        })
      }
    })
  }
  

  openAddCategory() {
    this.dialog.open(AddCategoriesComponent, {
      width: '600px',
      data: null
    }).afterClosed().subscribe((data)=> {
      
    });
  }

  openEditCategory(item) {
    this.dialog.open(AddCategoriesComponent, {
      width: '600px',
      data: item
    }).afterClosed().subscribe((data)=> {
      
    });
  }

}
