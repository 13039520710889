import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProductsService } from 'src/app/api/products/products.service';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
import { SendMailService } from 'src/app/providers/mails/send-mail.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {

  subscribersData: any;
  loading: boolean = true;

  constructor(
    public dialog: MatDialog,
    public mailsProvider: SendMailService,
    private confirmDialog: ConfirmDialogService,
    public snackBar: MatSnackBar,
  ) {
    this.loading = true;
    this.mailsProvider.getAllSubscribers().valueChanges().subscribe((data)=> {
      this.subscribersData = data;
      this.loading = false;
    })
  }

  ngOnInit() {

  }
}
