import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CertificationsService {

  certificationsDB: AngularFirestoreCollection<any> = this.afs.collection('mediex_certifications');

  constructor(
    private afs: AngularFirestore,
    public snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) { }

  getCertifications() {
    return this.certificationsDB;
  }

  getCertificationId(id) {
    let certification = this.afs.collection('mediex_certifications', ref => ref.where('id', '==', id));
    return certification;
  }

  addCertification(data) {
    return this.certificationsDB.add(data).then(res => {
      res.update({ id: res.id }).then(response => {
        this.snackBar.open('Certification added', 'CLOSE', {
          duration: 2000,
        });
      });
    })
  }

  updateCertification(data) {
    return this.certificationsDB.doc(data.id).update(data).then(response => {
      this.snackBar.open('Certification updated', 'CLOSE', {
        duration: 5000,
      });
    });
  }

  deleteCertification(data) {
    return this.certificationsDB.doc(data.id).delete().then(a => {
      this.snackBar.open('Certification is deleted', 'CLOSE', {
        duration: 5000,
      });
    });
  }
}
