import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProductsService } from 'src/app/api/products/products.service';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
import { AddProductComponent } from '../admin-products/add-product/add-product.component';

@Component({
  selector: 'app-quotation-requests',
  templateUrl: './quotation-requests.component.html',
  styleUrls: ['./quotation-requests.component.scss']
})
export class QuotationRequestsComponent implements OnInit {

  loading: boolean;
  quotationsRequestData: any[];

  constructor(
    public dialog: MatDialog,
    public productsProvider: ProductsService,
    public snackBar: MatSnackBar,
  ) {

  }

  ngOnInit() {
    this.loadData()
  }

  loadData() {
    this.loading = true;
    this.productsProvider.getQuotationsRequests().valueChanges().subscribe((data)=> {
      this.quotationsRequestData = data;
      this.loading = false;
    });
  }

  openAddProduct() {
    this.dialog.open(AddProductComponent, {
      width: '600px',
      data: null
    }).afterClosed().subscribe((data)=> {
      
    });
  }

  openEditProduct(item) {
    this.dialog.open(AddProductComponent, {
      width: '600px',
      data: item
    }).afterClosed().subscribe((data)=> {
      
    });
  }

}
