import { Component, OnInit } from '@angular/core';
import { AuthProvidersService } from 'src/app/api/auth/auth-providers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from 'src/app/api/products/products.service';
declare var UIkit: any;

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  loggedInUser: any;
  loading: boolean;
  quotationsRequest: any[];
  unreadQuotes: any;
  constructor(
    public authData: AuthProvidersService,
    public snackBar: MatSnackBar,
    private productsProvider: ProductsService
  ) { }

  ngOnInit() {
    this.authData.currentUser().subscribe((data) => {
      if(data) {
        this.loggedInUser = data;
        console.log(data);
        if(!data) {
          UIkit.modal('#modal-login').show();
        }
      }
      else {
        UIkit.modal('#modal-login').show();
      }
    });
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.productsProvider.getQuotationsRequests().valueChanges().subscribe((data)=> {
      this.quotationsRequest = data;
      this.loading = false;
      this.getUnreadQuoutes(data);
    });
  }

  getUnreadQuoutes(data) {
    this.unreadQuotes = data.filter((item) => !item.read).length;
  }

  logout() {
    this.authData.logoutUser().then((data) => {
      this.snackBar.open('You have been logged out', 'CLOSE', {
        duration: 5000,
      });
    })
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 10);
  }
}
