import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';


export interface MessageModel {
  names?: string,
  email?: string,
  subject?: string,
  message?: string
}


@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  subscribersDB: AngularFirestoreCollection<any> = this.afs.collection('mediex_subscribers');
  
  private emailUrl = 'mails.php';

    constructor(
      private httpClient: HttpClient,
      private afs: AngularFirestore,
      public snackBar: MatSnackBar
    ) {}


    sendMail(message: MessageModel): Observable<MessageModel> | any {
      return this.httpClient.post(this.emailUrl, message, {responseType: 'text'})
      .pipe(
        map(response => {
          console.log('Sending email was successfull',response);
          return response;
        }),
        catchError(error => {
          console.log('Sending email got error', error);
          return throwError(error)
        })
      );
    }

    addSubscriber(data) {
      return this.subscribersDB.add(data).then(res => {
        res.update({ id: res.id }).then(response => {
          this.snackBar.open('Subscribed successfully', 'CLOSE', {
            duration: 5000,
          });
        });
      })
    }

    getAllSubscribers() {
      return this.subscribersDB;
    }
}
