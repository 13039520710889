import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendMailService } from 'src/app/providers/mails/send-mail.service';
import { Title } from '@angular/platform-browser';
declare var UIkit: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  lat: number = -29.887768;
  lng: number = 31.039574;
  
  zoom: number = 15;
  
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private titleService: Title,
    private mailServices: SendMailService,
  ) {
    this.form = this.formBuilder.group({
      names: ['', Validators.required],
      contactNumber: ['', Validators.nullValidator],
      subject: ['', Validators.required],
      email: ['', Validators.required],
      notARobot: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.setTitle('Contact Us');
  }

  public setTitle( pageTitle: string) {
    this.titleService.setTitle( pageTitle );
  }

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  send() {
    delete this.form.value.contactNumber;
    delete this.form.value.notARobot;
    this.mailServices.sendMail(this.form.value)
    .subscribe((data) => {
        this.form.reset();
        this.form.untouched;
        UIkit.notification({message: 'Message was sent successfully', pos: 'bottom-center', status: 'success'});
    })
  }
}
