import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
import { AddProductComponent } from '../admin-products/add-product/add-product.component';
import { AddCertificateComponent } from './add-certificate/add-certificate.component';
import { CertificationsService } from 'src/app/api/certitications/certifications.service';

@Component({
  selector: 'app-products-certifications',
  templateUrl: './products-certifications.component.html',
  styleUrls: ['./products-certifications.component.scss']
})
export class ProductsCertificationsComponent implements OnInit {
  loading: boolean;
  certificatesData: any[];

  constructor(
    public dialog: MatDialog,
    private confirmDialog: ConfirmDialogService,
    public snackBar: MatSnackBar,
    public certificationsProvider: CertificationsService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.certificationsProvider.getCertifications().valueChanges().subscribe((data) => {
      this.certificatesData = data;
      this.loading = false;
    })
  }

  openDeleteCertification(data) {
    this.confirmDialog.openConfirmDialog('delete', 'certification', data).subscribe(res => {
      if(res) {
        this.certificationsProvider.deleteCertification(data);
      }
    })
  }
  

  openAddCertification() {
    this.dialog.open(AddCertificateComponent, {
      width: '600px',
      data: null
    }).afterClosed().subscribe((data)=> {
      
    });
  }

  openEditCertification(item) {
    this.dialog.open(AddCertificateComponent, {
      width: '600px',
      data: item
    }).afterClosed().subscribe((data)=> {
      
    });
  }


}
