import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/api/products/products.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  selectedCategory: any = '';
  productsData: any;
  loading: boolean;
  categoryDetails: any;
  allCategoriesData: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public productsProvider: ProductsService,
    private titleService: Title,
  ) {
    this.route.params.subscribe(params => {
        this.selectedCategory = params['category'];
        if(this.selectedCategory === 'all') {
          this.titleService.setTitle('All Products');
        }
    });
  }

  ngOnInit() {
    this.loadAllCategories();
    this.loadPorducts();
  }

  loadAllCategories() {
    this.loading = true;
    this.productsProvider.getCategories().valueChanges().subscribe((data) => {
      this.loading = false;
      this.allCategoriesData = data;
    });
  }

  loadPorducts() {
    this.loading = true;
    if(this.selectedCategory === 'all') {
      this.productsProvider.getProducts().valueChanges().subscribe((data)=> {
        this.loading = false;
        this.productsData = data;
      });
    }
    else {
      this.productsProvider.getProductsByCategory(this.selectedCategory).valueChanges().subscribe((data) => {
        this.productsData = data;
        this.loading = false;
      });
      this.productsProvider.getCategoryById(this.selectedCategory).valueChanges().subscribe((data)=> {
        this.loading = false;
        this.categoryDetails = data[0];
        this.titleService.setTitle(`${this.categoryDetails.categoryName} - Products`);
      })
    }
  }

  displayCategory(category) {
    return this.productsData.filter((item) => item.category === category.id).length;
  }
}
