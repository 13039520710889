import { Component, OnInit } from '@angular/core';
import { AddArticleComponent } from './add-article/add-article.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
import { ArticlesService } from 'src/app/api/articles/articles.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  loading: boolean; 
  articlesData: any[];
  constructor(
    public dialog: MatDialog,
    private confirmDialog: ConfirmDialogService,
    public snackBar: MatSnackBar,
    private articlesProvider: ArticlesService
  ) { }

  ngOnInit() {
    this.loadArticles();
  }


  loadArticles() {
    this.loading = true;
    this.articlesProvider.getArticles().valueChanges().subscribe((data)=> {
      this.articlesData = data;
      this.loading = false;
    })
  }

  openAddArticle() {
    this.dialog.open(AddArticleComponent, {
      width: '800px',
      data: null
    }).afterClosed().subscribe((data)=> {
      
    });
  }

  openEditArticle(item) {
    this.dialog.open(AddArticleComponent, {
      width: '800px',
      data: item
    }).afterClosed().subscribe((data)=> {
      
    });
  }
  


  openDeleteArticle(data) {
    this.confirmDialog.openConfirmDialog('delete', 'article', data).subscribe(res => {
      if(res) {
        this.articlesProvider.deleteArticle(data).then((data) => {
          // this.snackBar.open(data['message'], 'CLOSE', { duration: 5000 });
        })
      }
    })
  }

}
