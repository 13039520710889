import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/api/products/products.service';
import { ViewportScroller } from '@angular/common';
import { CertificationsService } from 'src/app/api/certitications/certifications.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean;
  productsData: any[];
  categoriesData: any[];
  certificatesData: any[];

  constructor(
    public productsProvider: ProductsService,
    public certificationsProvider: CertificationsService,
    private viewportScroller: ViewportScroller,
    private titleService: Title,
  ) {

  }

  ngOnInit() {
    this.loadCategories();
    this.loadProducts();
    this.loadCertifications();
    this.setTitle('Medi-ex:: Home page');
  }

  public goToCetifications(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  public setTitle( pageTitle: string) {
    this.titleService.setTitle( pageTitle );
  }

  loadProducts() {
    this.loading = true;
    this.productsProvider.getCategories().valueChanges().subscribe((data)=> {
      this.categoriesData = data;
      this.loading = false;
    });
  }

  loadCertifications() {
    this.loading = true;
    this.certificationsProvider.getCertifications().valueChanges().subscribe((data) => {
      this.certificatesData = data;
      this.loading = false;
    })
  }

  loadCategories() {
    this.productsProvider.getProducts().valueChanges().subscribe((data)=> {
      this.productsData = data;
      this.loading = false;
    })
  }

}
