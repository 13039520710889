import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var UIkit: any;
@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  productsDB: AngularFirestoreCollection<any> = this.afs.collection('mediex_products');
  categoriesDB: AngularFirestoreCollection<any> = this.afs.collection('mediex_categories');
  quotationRequestsDB: AngularFirestoreCollection<any> = this.afs.collection('mediex_quotation_requests');
  itemOncart: any;
  constructor(
    private afs: AngularFirestore,
    public snackBar: MatSnackBar
  ) { }


  getCategories() {
    let categoriesData = this.afs.collection('mediex_categories', ref => ref.orderBy("sortingNumber", "asc"));
    return categoriesData;
  }

  getCategoryById(id) {
    let selectedCategory = this.afs.collection('mediex_categories', ref => ref.where('id', '==', id));
    return selectedCategory;
  }

  addCategory(data) {
    return this.categoriesDB.add(data).then(res => {
      res.update({ id: res.id }).then(response => {
        this.snackBar.open('Category added', 'CLOSE', {
          duration: 2000,
        });
      });
    })
  }

  updateCategory(data) {
    return this.categoriesDB.doc(data.id).update(data).then(response => {
      this.snackBar.open('Category updated', 'CLOSE', {
        duration: 5000,
      });
    });
  }

  deleteCategory(data) {
    return this.categoriesDB.doc(data.id).delete().then(a => {
      this.snackBar.open('Category is deleted', 'CLOSE', {
        duration: 5000,
      });
    });
  }

  // ////////////// /////////// ////////////// //////////


  getProducts() {
    let productData = this.afs.collection('mediex_products', ref => ref.orderBy("sortingNumber", "asc"));
    return productData;
  }

  getProductsByCategory(id) {
    let filteredProducts = this.afs.collection('mediex_products', ref => ref.where('category', '==', id).orderBy("sortingNumber", "asc"));
    return filteredProducts;
  }

  getProductById(id) {
    let selectedProduct = this.afs.collection('mediex_products', ref => ref.where('id', '==', id));
    return selectedProduct;
  }

  addProduct(data) {
    return this.productsDB.add(data).then(res => {
      res.update({ id: res.id }).then(response => {
        this.snackBar.open('Product added', 'CLOSE', {
          duration: 2000,
        });
      });
    })
  }

  updateProduct(data) {
    return this.productsDB.doc(data.id).update(data).then(response => {
      this.snackBar.open('Product updated', 'CLOSE', {
        duration: 5000,
      });
    });
  }

  deleteProduct(data) {
    return this.productsDB.doc(data.id).delete().then(a => {
      this.snackBar.open('Product is deleted', 'CLOSE', {
        duration: 5000,
      });
    });
  }

  addProductToCart(product) {
    let productsOnCart = localStorage.getItem('mediex_products');
    this.itemOncart = JSON.parse(productsOnCart);
    if(this.itemOncart) {
      let exists = this.itemOncart.find((item) => item.id === product.id);
      console.log(exists);
      if(exists) {
        UIkit.notification({message: 'Product already added', pos: 'bottom-center', status: 'warning'});
      }
      else {
        this.itemOncart.push(product);
        localStorage.setItem("mediex_products", JSON.stringify(this.itemOncart));
        UIkit.notification({message: 'Product added to cart', pos: 'bottom-center', status: 'success'});
      }
    }
    else {
      localStorage.setItem("mediex_products", JSON.stringify([product]));
      UIkit.notification({message: 'Product added to cart', pos: 'bottom-center', status: 'success'});
    }
  }

  // //////// //////////  /////////// ////////////

  // QUOTES

  getQuotationsRequests() {
    return this.afs.collection('mediex_quotation_requests', ref => ref.orderBy('read'));
  }

  addQuotationRequest(data) {
    return this.quotationRequestsDB.add(data).then(res => {
      res.update({ id: res.id }).then(response => {
        this.snackBar.open('You request has been received', 'CLOSE', {
          duration: 8000,
        });
      });
    })
  }

  updateQuotationRequest(data) {
    return this.quotationRequestsDB.doc(data.id).update(data).then(response => {
      // this.snackBar.open('Product updated', 'CLOSE', {
      //   duration: 5000,
      // });
    });
  }

  getQuoteById(id) {
    let selectedQuote = this.afs.collection('mediex_quotation_requests', ref => ref.where('id', '==', id));
    return selectedQuote;
  }
}