import { Component, OnInit, OnChanges } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { IBreadCrumb } from './breadcrumb.interface';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  breadcrumbs: any

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .pipe(map(() => this.activatedRoute))
    .pipe(map((route) => {
      while (route.firstChild) { route = route.firstChild; }
      return route;
    }))
    .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
    .subscribe(route => {

      let snapshot = this.router.routerState.snapshot;
      this.breadcrumbs = [];
      let url = snapshot.url;
      let routeData = route.snapshot.data;

      let label = routeData['breadcrumb'];
      let params = snapshot.root.params;

      this.breadcrumbs.push({
        url: url,
        label: label,
        params: params
      });
    });
  }

  ngOnInit() {

  }

  ngOnChanges() {

  }
}
