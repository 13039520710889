import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { getLinkPreview } from 'link-preview-js';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  articlesDB: AngularFirestoreCollection<any> = this.afs.collection('mediex_articles');
  apiKey = '5c66a41cf8f7468a15626039bb5c549f';
  linkPreviewApiUrl = `https://api.linkpreview.net/?key=${ this.apiKey }&q=`;
  constructor(
    private afs: AngularFirestore,
    public snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {}


  generateArticleData(articleUrl) {
    return this.httpClient.get(this.linkPreviewApiUrl+articleUrl);
  }



  getArticles() {
    return this.afs.collection('mediex_articles', ref => ref.orderBy('dateAdded', 'desc'));
  }

  getArticleId(id) {
    let article = this.afs.collection('mediex_articles', ref => ref.where('id', '==', id));
    return article;
  }

  addArticle(data) {
    return this.articlesDB.add(data).then(res => {
      res.update({ id: res.id }).then(response => {
        this.snackBar.open('Article added', 'CLOSE', {
          duration: 2000,
        });
      });
    })
  }

  updateArticle(data) {
    return this.articlesDB.doc(data.id).update(data).then(response => {
      this.snackBar.open('Article updated', 'CLOSE', {
        duration: 5000,
      });
    });
  }

  deleteArticle(data) {
    return this.articlesDB.doc(data.id).delete().then(a => {
      this.snackBar.open('Article is deleted', 'CLOSE', {
        duration: 5000,
      });
    });
  }

  getHostname = (url) => {
    return new URL(url).hostname;
  }
}
