import { Component, OnInit } from '@angular/core';
import { AddProductComponent } from './add-product/add-product.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProductsService } from 'src/app/api/products/products.service';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.scss']
})
export class AdminProductsComponent implements OnInit {
  loading: boolean;
  productsData: any[];
  categoriesData: any[];
  selectedCategory = new FormControl('ALL');

  constructor(
    public dialog: MatDialog,
    private confirmDialog: ConfirmDialogService,
    public snackBar: MatSnackBar,
    public productsProvider: ProductsService,
  ) {

  }

  ngOnInit() {
    this.loadData();
    this.loadProductsByCategory(this.selectedCategory.value);
  }

  onCategoryChange(id) {
    this.loadProductsByCategory(id.value);
  }

  loadProductsByCategory(id) {
    this.loading = true;
    if(id !== 'ALL') {
      this.productsProvider.getProductsByCategory(id).valueChanges().subscribe((data) => {
        this.productsData = data;
        this.loading = false;
      })
    }
    else {
      this.loadData();
    }
  }

  loadData() {
    this.loading = true;
    this.productsProvider.getCategories().valueChanges().subscribe((data)=> {
      this.categoriesData = data;
      this.loading = false;
    });

    this.productsProvider.getProducts().valueChanges().subscribe((data)=> {
      this.productsData = data;
      this.loading = false;
    })
  }

  getCategoryDetails(item) {
    let selectedCategory = this.categoriesData.filter((category) => category.id === item);
    return selectedCategory[0];
  }

  openDeleteProduct(data) {
    this.confirmDialog.openConfirmDialog('delete', 'product', data).subscribe(res => {
      if(res) {
        this.productsProvider.deleteProduct(data).then((data) => {
          // this.snackBar.open(data['message'], 'CLOSE', { duration: 5000 });
        })
      }
    })
  }
  

  openAddProduct() {
    this.dialog.open(AddProductComponent, {
      width: '600px',
      data: null
    }).afterClosed().subscribe((data)=> {
      // this.loadData(); 
    });
  }

  openEditProduct(item) {
    this.dialog.open(AddProductComponent, {
      width: '600px',
      data: item
    }).afterClosed().subscribe((data)=> {
      // this.loadData(); 
    });
  }

}
