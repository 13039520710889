import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
declare var UIkit: any;


@Injectable()
export class AuthProvidersService {
  
  usersDB: AngularFirestoreCollection<any> = this.afs.collection('users');

  constructor(public afAuth: AngularFireAuth, public afd: AngularFireDatabase, private afs: AngularFirestore) {}

  loginUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  getLoggedInUser(id) {
    let userDetails = this.afs.collection('users', ref => ref.where('uid', '==', id));
    return userDetails;
  }

  getAllUsers() {
    return this.usersDB;
  }

  getUserDetails(id) {
    let userData = this.afs.collection('users', ref => ref.where('id', '==', id));
    return userData;
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<any> {
    return this.afAuth.auth.signOut();
  }

  signupUser(userData): Promise<any> {

    return this.afAuth.auth.createUserWithEmailAndPassword(userData.email, userData.password).then((data) =>{
      this.afAuth.auth.currentUser.sendEmailVerification().then(function() {
        // Email sent.
        UIkit.notification({ message: 'verification email has been sent to ' + userData.email, pos: 'bottom-center' });
       }, function(error) { console.log('An error happened'); });

      this.afAuth.auth.currentUser.updateProfile({ displayName: userData.names, photoURL:"null" }).then(() => {
        this.usersDB.add({ name: userData.names, uid: data.user.uid, email: data.user.email, userType: 'SUBSCRIBER' }).then(res => {
          res.update({ id: res.id }).then(response => {
          });
        })
      })
    });
  }

  currentUser(): any {
    return this.afAuth.authState
  }

}
