import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AddProductComponent } from '../../admin-products/add-product/add-product.component';
import { CertificationsService } from 'src/app/api/certitications/certifications.service';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss']
})
export class AddCertificateComponent implements OnInit {

  form: FormGroup;
  certificationData: any;
  uploadedCertificationUrl: string;
  urlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  saving: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private certificateProvider: CertificationsService,
    public snackBar: MatSnackBar,
  ) {
    this.form = this.formBuilder.group({
      certificationName: [this.data ? this.data.certificationName : null, Validators.required],
      certificationShortName: [this.data ? this.data.certificationShortName : null, Validators.required],
      description: [this.data ? this.data.description : null, Validators.required],
      website: [this.data ? this.data.website : null, Validators.pattern(this.urlRegex)],
    });

    this.uploadedCertificationUrl = this.data ? this.data.certificateUrl : null;
  }

  ngOnInit() {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onCertificateUploaded(url) {
    this.uploadedCertificationUrl = url;
  }

  removeCerticicate() {
    this.uploadedCertificationUrl = '';
  }

  save() {
    this.saving = true;
    this.certificationData = this.form.value;
    if(this.data) {
      this.certificationData.id = this.data.id;
      this.certificationData.certificateUrl = this.uploadedCertificationUrl;
      this.certificateProvider.updateCertification(this.certificationData).then((data)=> {

      this.saving = false;
        this.onCancel();
      })
    }
    else {
      this.certificationData.certificateUrl = this.uploadedCertificationUrl;
      this.certificateProvider.addCertification(this.certificationData).then((data)=> {
        this.saving = false;
        this.onCancel();
      })
    }
  }

}
