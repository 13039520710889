import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from 'src/app/api/products/products.service';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.scss']
})
export class AddCategoriesComponent implements OnInit {
  form: FormGroup;
  userData: any;
  uploadedImageUrl: any = '';

  constructor(
    public dialogRef: MatDialogRef<AddCategoriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private productsProvider: ProductsService,
    public snackBar: MatSnackBar,
  ) {
    this.form = this.formBuilder.group({
      categoryName: [this.data ? this.data.categoryName : null, Validators.required],
      sortingNumber: [this.data ? this.data.sortingNumber : null, Validators.required]
    });
    this.uploadedImageUrl = this.data ? this.data.imageUrl : '';
  }

  ngOnInit() {
    console.log(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onImageUploaded(url) {
    this.uploadedImageUrl = url;
  }

  removeImage() {
    this.uploadedImageUrl = ''
  }
  
  save() {
    this.userData = this.form.value;
    // this.userData.imageUrl = !this.data.imageUrl ? this.imageUrl : this.data.imageUrl;
    if(this.data) {
      this.userData.id = this.data.id;
      this.userData.imageUrl = this.uploadedImageUrl ? this.uploadedImageUrl : this.data.imageUrl;
      this.productsProvider.updateCategory(this.userData).then((data)=> {
        this.onCancel();
      })
    }
    else {
      this.userData.imageUrl = this.uploadedImageUrl;
      this.productsProvider.addCategory(this.userData).then((data)=> {
        this.onCancel();
      })
    }
  }

}
