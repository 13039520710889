import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SendMailService } from 'src/app/providers/mails/send-mail.service';
import { ProductsService } from 'src/app/api/products/products.service';
declare var UIkit: any;

@Component({
  selector: 'app-request-quote-modal',
  templateUrl: './request-quote-modal.component.html',
  styleUrls: ['./request-quote-modal.component.scss']
})
export class RequestQuoteModalComponent implements OnInit {
  form: FormGroup;
  @Input() selectedProduct: any;
  formData: any;
  loading: boolean;
  itemOncart: any;
  constructor(
    private formBuilder: FormBuilder,
    private mailServices: SendMailService,
    public productsProvider: ProductsService
  ) {
    this.form = this.formBuilder.group({
      names: ['', Validators.required],
      contactNumber: ['', Validators.nullValidator],
      quatity: [1, Validators.required],
      email: ['', Validators.required],
      additionalMessage: ['', Validators.nullValidator],
    });
  }

  ngOnInit() {
    setInterval(() => { 
      let productsOnCart = localStorage.getItem('mediex_products');
      this.itemOncart = JSON.parse(productsOnCart);
    }, 1000);
  }

  send() {
    this.loading = true;
    this.formData = this.form.value;
    this.formData.read = false;
    this.formData.dateSent = new Date();
    this.formData.productsOnRequest = this.itemOncart;
    console.log(this.formData);
    this.productsProvider.addQuotationRequest(this.formData).then((data)=> {
      this.loading = false;
      localStorage.removeItem('mediex_products');
      UIkit.modal('#modal-quote-request').hide();
    })
    this.mailServices.sendMail(this.formData)
    .subscribe((data) => {
        this.form.reset();
        this.form.untouched;
        UIkit.notification({message: 'Message was sent successfully', pos: 'bottom-center', status: 'success'});
    })
  }

  removeFromCart(index) {
    this.itemOncart.splice(index, 1);
    localStorage.setItem("mediex_products", JSON.stringify(this.itemOncart));
  }

}
