import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthProvidersService } from 'src/app/api/auth/auth-providers.service';
declare var UIkit: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  loading: boolean = false;
  form: FormGroup;
  
  constructor(
    public authData: AuthProvidersService,
    private formBuilder: FormBuilder
    ) {

      this.form = this.formBuilder.group({
        email: ['', Validators.required], //admin@mediex.co.za
        password: ['', Validators.required] //admin@2020
      })
  }

  ngOnInit() {
  }

  loginUser() {
    this.loading = true;
    this.authData.loginUser(this.form.value.email, this.form.value.password)
      .then( authData => {
        UIkit.modal('#modal-login').hide();
        this.loading = false;
        this.form = this.formBuilder.group({email: [''],password: ['']});
      }, (error) => {
        console.log(error);
        UIkit.notification({message: error.message, pos: 'bottom-center', status: 'danger'});
        this.loading = false;
      });
    }

}
