import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/api/products/products.service';

@Component({
  selector: 'app-quotation-request',
  templateUrl: './quotation-request.component.html',
  styleUrls: ['./quotation-request.component.scss']
})
export class QuotationRequestComponent implements OnInit {
  selectedQuotationRequest: any;
  selectedQuotationRequestData: any;
  loading: boolean;
  categoriesData: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public productsProvider: ProductsService
  ) {
    this.loading = true;
    this.route.params.subscribe(params => {
      this.selectedQuotationRequest = params.id;
      console.log(params.id);
      this.productsProvider.getQuoteById(this.selectedQuotationRequest).valueChanges().subscribe((data)=> {
        this.selectedQuotationRequestData = data[0];
        this.loading = false;
        this.updateQupteRequest(data[0]);
      });

    });
    this.productsProvider.getCategories().valueChanges().subscribe((data)=> {
      this.categoriesData = data;
      this.loading = false;
    });
  }

  ngOnInit() {
  }

  getCategoryDetails(id) {
    let selectedCategory = this.categoriesData.filter((category) => category.id === id);
    return selectedCategory[0];
  }

  updateQupteRequest(data) {
    const newData = data;
    newData.read = true;
    this.loading = true;
    this.productsProvider.updateQuotationRequest(newData).then((res)=> {
      this.loading = false;
      console.log(res);
    });
  }

}
