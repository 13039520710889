import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AddProductComponent } from '../../admin-products/add-product/add-product.component';
import { ProductsService } from 'src/app/api/products/products.service';
import { ArticlesService } from 'src/app/api/articles/articles.service';

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent implements OnInit {


  form: FormGroup;
  articleData: any;
  loading: boolean;
  loadedArticle: any;
  urlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  articleSourceUrl: any;

  constructor(
    public dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private articlesProvider: ArticlesService,
    public snackBar: MatSnackBar,
  ) {

    this.articleSourceUrl = new FormControl(this.data ? this.data.url : '', [
      Validators.required,
      Validators.pattern(this.urlRegex),
    ]);
    this.loadedArticle = this.data ? this.data : null;
  }

  ngOnInit() {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  loadArticleFromSource() {
    this.loading = true;
    console.log(this.articleSourceUrl);
    this.articlesProvider.generateArticleData(this.articleSourceUrl.value).subscribe((data)=> {
      console.log(data);
      this.loadedArticle = data;
      this.loadedArticle.hostUrl = this.articlesProvider.getHostname(this.loadedArticle.url);
      this.loading = false;
    })
  }

  save() {
    this.loading = true;
    this.articleData = this.loadedArticle;
    if(this.data) {
      this.articleData.id = this.data.id;
      this.articleData.dateUpdated = new Date();
      this.articlesProvider.updateArticle(this.articleData).then((data)=> {
        this.onCancel();
        this.loading = false;
      })
    }
    else {
      this.articleData.dateAdded = new Date();
      this.articleData.dateUpdated = new Date();
      this.articlesProvider.addArticle(this.articleData).then((data)=> {
        this.onCancel();
        this.loading = false;
      })
    }
  }



}
