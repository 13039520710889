import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SendMailService } from 'src/app/providers/mails/send-mail.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  myRecaptcha = new FormControl(false);
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private mailServices: SendMailService,
  ) {
    this.form = this.formBuilder.group({
      names: ['', Validators.required],
      emailAddress: ['', Validators.required],
      notARobot: [false, Validators.required],
    });
  }

  ngOnInit() {
  }

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  send() {
    console.log(this.form.value);
    delete this.form.value.notARobot;
    this.mailServices.addSubscriber(this.form.value).then(()=> {
      this.form.reset();
      this.form.untouched;
    });
  }

}
