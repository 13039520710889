// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCjEoJzjI0xeeZH8LOmwRBXoIupU06m-8U",
    authDomain: "ericap-1475067075092.firebaseapp.com",
    databaseURL: "https://ericap-1475067075092.firebaseio.com",
    projectId: "ericap-1475067075092",
    storageBucket: "ericap-1475067075092.appspot.com",
    messagingSenderId: "73348833979",
    appId: "1:73348833979:web:86f6dc2b82c6eec1e3bf9e",
    measurementId: "G-0QYBRR6KBX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
